<template>
    <div class="page" id="reorganize">
        <el-row :gutter="10">
          <el-col :span="showList ? 4 : 1" :class="['p_r', showList ? '' : 'el-col-unfold']">
            <div :class="['unfoldClassifyBtn', showList ? 'el-icon-arrow-left':'el-icon-arrow-right']"
                 @click="showList = !showList"></div>
            <div class="leftHeighe bg-white" v-show="showList">
                    <el-descriptions title="文物类别"></el-descriptions>
                    <div class="flex_b_c">
                        <el-input size="small" placeholder="输入关键字进行过滤" clearable v-model="filterText"
                                  @input="filterTreeData"></el-input>
                    </div>
                    <el-tree v-loading="treeLoading" node-key="id" ref="tree" :data="treeData" class="treeDateCls"
                             show-checkbox :filter-node-method="filterNode"
                             :props="defaultProps" @check="selRecord">
                        <div slot-scope="{ node, data }">
                            <el-tooltip class="item" effect="dark" :content="node.label" placement="top-start">
                                <div class="omit">{{ node.label }}</div>
                            </el-tooltip>
                        </div>
                    </el-tree>
                </div>
            </el-col>
          <el-col :span="showList ? 20 : 24" :class="[showList ? '' : 'el-col-unfold','leftHeighe']">
                <div class="text_center noData bg-white" v-show="!archivesBasicDataId.length">请选择左侧文物类别</div>
                <div v-show="archivesBasicDataId.length">
                    <div class="bg-white">
                        <el-form class="query-form dynamicForm tabsCls2" size="small" ref="inputForm"
                                 :model="inputForm"
                                 @keyup.enter.native="getDataList(1,1)"
                                 :rules="rules" label-width="150px">
                            <el-form-item label="文物名称" prop="name">
                                <el-input v-model.trim="inputForm.name"
                                          placeholder="请输入文物名称(限50字)"
                                          maxlength="50" clearable></el-input>
                            </el-form-item>
                            <el-form-item label="现状情况" prop="heritageAppraisal">
                                <el-select v-model="inputForm.heritageAppraisal" clearable
                                           placeholder="请选择现状情况" style="width: 100%;">
                                    <el-option
                                            v-for="item in this.$dictUtils.getDictList('assessment_status')"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="年份（年）" prop="year">
                                <el-date-picker
                                        v-model="inputForm.year"
                                        clearable
                                        type="year"
                                        style="width: 100%;"
                                        placeholder="选择年份">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item label="完残程度" prop="integrity">
                                <el-select v-model="inputForm.integrity"
                                           placeholder="请选择完残程度"
                                           style="width: 100%"
                                           clearable>
                                    <el-option
                                        v-for="item in integrityList"
                                        :key="item.id"
                                        :label="item.levelName"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="完残情况" prop="integrityInfo">
                                <el-input v-model="inputForm.integrityInfo"
                                          placeholder="请输入完残情况(限50字)"
                                          maxlength="50" clearable></el-input>
                            </el-form-item>
                        </el-form>
                        <div class="flex_b_c">
                            <div>
                                <el-button size="small" type="primary" @click="getDataList(1,1)" icon="el-icon-search">
                                    查询
                                </el-button>
                                <el-button size="small" @click="resetting()" icon='el-icon-refresh-right'>重置
                                </el-button>
                            </div>
                            <div>
                                <el-button type="primary"
                                           size="small"
                                           icon="el-icon-circle-check"
                                           v-show="hasPermissionButton(`collection:immovable:${typePage}:batchThrou`)"
                                           @click="through(1,'',3)">批量通过
                                </el-button>
                                <el-button type="danger"
                                           size="small"
                                           icon="el-icon-circle-close"
                                           v-show="hasPermissionButton(`collection:immovable:${typePage}:batchReject`)"
                                           @click="through(1,'',2)">批量驳回
                                </el-button>
                            </div>
                        </div>
                    </div>
                    <div class="bg-white magTop">
                        <div class="text_right">
                            <el-button size="small"
                                       v-show="hasPermissionButton(`collection:immovable:${typePage}:batchExpor`)"
                                       @click="exportData(1)">
                                <i class="icon-piliangdaochu iconfont buIcon"/>
                                批量导出
                            </el-button>
                        </div>
                        <el-table
                                :data="dataList"
                                size="small"
                                v-loading="loading"
                                ref="multipleTable"
                                height="calc(100vh - 350px)"
                                class="table"
                                :row-key="'id'"
                                :header-cell-style="{background:'#F9DFDF'}"
                                @selection-change="selectionChangeHandle"
                        >
                            <el-table-column :reserve-selection="true" type="selection" width="50"/>
                            <el-table-column label="序号" type="index" width="50"></el-table-column>
                            <el-table-column prop="processState" label="审核状态" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    {{ $dictUtils.getDictLabel("process_state", scope.row.processState, '-') }}
                                </template>
                            </el-table-column>
                            <el-table-column prop="name" label="图标" width="120px">
                                <template slot-scope="scope">
                                    <div class="flex_a_c">
                                        <div v-if="scope.row.carded == 0"
                                             class="icon-wenwukapian iconfont listIcon"></div>
                                        <el-image
                                                style="width: 36px; height: 36px"
                                                :src="scope.row.picMasterViewUrl"
                                                :fit="'cover'"
                                                :preview-src-list="[scope.row.picMasterViewUrl]"
                                        >
                                            <div slot="error" class="image-slot">
                                                <el-image
                                                        style="width: 36px; height: 36px"
                                                        :src="require('@/assets/img/default.png')"
                                                        :fit="'cover'">
                                                </el-image>
                                            </div>
                                        </el-image>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="name" label="文物名称"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="culturalRelicsCategory" label="文物类别" show-overflow-tooltip>
                            </el-table-column>
                            <el-table-column prop="culturalRelicsType" label="文物分类" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    {{ scope.row.culturalRelicsType ? scope.row.culturalRelicsType : scope.row.other }}
                                </template>
                            </el-table-column>
                            <el-table-column prop="quantity" label="数量" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="year" label="年份" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="integrityInfo" label="完残情况" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="integrity" label="完残程度" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="heritageAppraisal" label="现状评估" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    {{ $dictUtils.getDictLabel("assessment_status", scope.row.heritageAppraisal, '-') }}
                                </template>
                            </el-table-column>
                            <el-table-column prop="createName" label="创建者" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="createDate" sortable label="创建时间" width="150px">
                                <template slot-scope="scope">
                                    {{ scope.row.createDate | formatDate }}
                                </template>
                            </el-table-column>
                            <el-table-column prop="updateName" label="最后更新人"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="updateDate" sortable label="最后更新时间" width="150px"
                                             show-overflow-tooltip>
                                <template slot-scope="scope">
                                    {{ scope.row.updateDate | formatDate }}
                                </template>
                            </el-table-column>
                            <el-table-column fixed="right" label="操作" width="180">
                                <template slot-scope="scope">
                                    <el-button style="margin-bottom: 10px" size="mini" type="text"
                                               @click="addData(3, scope.row,scope.$index)"
                                               v-show="hasPermissionButton(`collection:immovable:${typePage}:detail`)">
                                        详情
                                    </el-button>
                                    <el-button style="margin-bottom: 10px" size="mini" type="text"
                                               @click="record(scope.row)"
                                               v-show="hasPermissionButton(`collection:immovable:${typePage}:record`)">
                                        审核记录
                                    </el-button>
                                    <el-button style="margin-bottom: 10px" size="mini" type="text"
                                               @click="through(2,scope.row,3)"
                                               v-show="hasPermissionButton(`collection:immovable:${typePage}:through`)">通过
                                    </el-button>
                                    <el-button style="margin-bottom: 10px" size="mini" type="text"
                                               @click="through(2,scope.row,2)"
                                               v-show="hasPermissionButton(`collection:immovable:${typePage}:reject`)">驳回
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div class="text_center">
                            <el-pagination
                                    @size-change="sizeChangeHandle"
                                    @current-change="currentChangeHandle"
                                    :current-page="pageNo"
                                    :page-sizes="[10, 20, 50, 100]"
                                    :page-size="pageSize"
                                    :total="total"
                                    background
                                    layout="total, sizes, prev, pager, next, jumper"
                            >
                            </el-pagination>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
        <reviewSuggestions ref="suggestions" @updataStatus="getDataList('',1)"></reviewSuggestions>
        <record ref="record"></record>
        <ExportData ref="exportData" @downLoad="getDataList('',1)"></ExportData>
    </div>
</template>

<script>
import record from "@/views/modules/collection/accounts/module/record.vue";
import ExportData from "@/views/modules/record/collect/exportData.vue";
import reviewSuggestions from "@/views/modules/collection/accounts/module/reviewSuggestions.vue";

export default {
    name: "ImmovableAuditing",
    components: {reviewSuggestions, ExportData, record},
    data() {
        return {
            typePage: 'ImmovableAuditing',

            treeData: [],
            treeLoading: false,
            filterText: '',
            defaultProps: {
                children: 'childList',
                label: 'name'
            },
          showList:true,

            inputForm: {
                name: '',
                heritageAppraisal: '',
                year: '',
                integrity:'',
                integrityInfo:'',
            },
            rules: {},
            integrityList: [
                {
                    id: '残缺',
                    levelName: '残缺',
                },
                {
                    id: '基本完整',
                    levelName: '基本完整',
                },
                {
                    id: '完整',
                    levelName: '完整',
                },
                {
                    id: '严重残缺',
                    levelName: '严重残缺',
                },
            ],
            culturalRelicsTypeList: [],

            archivesBasicDataId: [],
            dataList: [],
            dataListSelect: [],
            loading: false,
            pageNo: 1,
            pageNo2: 0,
            pageSize: 10,
            total: 0,
            searchRecord: {},
        }
    },

    mounted() {
        let listSearch = JSON.parse(sessionStorage.getItem('listSearch'))
        if (listSearch) {
            this.inputForm = listSearch.inputForm
            this.pageSize = listSearch.pageSize
            this.pageNo2 = listSearch.pageNo
            this.archivesBasicDataId = listSearch.archivesBasicDataId
            this.searchTree(1)
        } else {
            this.searchTree()
        }
    },

    methods: {
        // 查询树状数据
        searchTree(type) {
            this.treeData = [
                {
                    id: '',
                    name: '全部',
                    childList: [],
                },
            ]
            this.treeLoading = true
            this.$axios(this.api.collection.collectionImmovableCulturalRelicTypeList, {}, 'get').then((res) => {
                if (res.status) {
                    this.treeData[0].childList = res.data
                    //全选
                    this.$nextTick(() => {
                        if (!type) {
                            this.getTreeId(this.treeData)

                        }
                        this.$refs.tree.setCheckedKeys(this.archivesBasicDataId);
                        this.selRecord('', '')
                    })
                }
                this.treeLoading = false
            })
        },

        //循环取树形控件id
        getTreeId(data) {
            if (data && data.length != 0) {
                data.forEach(item => {
                    this.archivesBasicDataId.push(item.id)
                    if (item.childList) {
                        this.getTreeId(item.childList)
                    }
                })
            } else {
                return
            }
        },

        //获取列表数据
        getDataList(type, dividePage) {
            if (type == 1) {
                this.pageNo = 1
            }
            if (dividePage == 1) {
                this.$refs.multipleTable.clearSelection()
            }
            this.loading = true
            let archivesBasicDataId
            archivesBasicDataId = this.archivesBasicDataId.filter(item => {
                return item != ''
            })
            this.searchRecord = {
                processState: [1],
                ...this.inputForm,
                current: this.pageNo2 ? this.pageNo2 : this.pageNo,
                size: this.pageSize,
                culturalRelicsCategory: archivesBasicDataId,
            }
            this.$axios(this.api.collection.collectionImmovableCulturalRelicListExamine, this.searchRecord, 'post').then((res) => {
                if (res.status) {
                    this.dataList = res.data.records
                    this.$nextTick(() => {
                        this.$refs.multipleTable.doLayout()
                    })
                    this.total = parseInt(res.data.total)
                    let listSearch = JSON.parse(sessionStorage.getItem('listSearch'))
                    if (listSearch) {
                        this.pageNo = listSearch.pageNo
                        this.pageNo2 = 0
                        sessionStorage.removeItem('listSearch')
                    }
                    if (this.dataList.length == 0 && this.pageNo > 1) {
                        this.pageNo--
                        this.getDataList()
                    }
                } else {
                    this.$message.error('查询失败');
                }
                this.loading = false
            })
        },

        //重置
        resetting() {
            this.$refs.inputForm.resetFields();
            this.getDataList(1, 1)
        },

        // 0新增 1详情 2修改 3审核
        addData(num, row, index) {
            if (num != 0) {
                let listSearch = {
                    inputForm: this.inputForm,
                    pageNo: this.pageNo,
                    pageSize: this.pageSize,
                    archivesBasicDataId:this.archivesBasicDataId,
                }
                sessionStorage.setItem('listSearch', JSON.stringify(listSearch))
                this.$set(this.searchRecord, 'viewCurrent', (this.pageNo - 1) * this.pageSize + index + 1)
                sessionStorage.setItem('registrationSearch', JSON.stringify(this.searchRecord))
            }
            this.$router.push({
                path: '/collection/immovable/addMovable',
                query: {butType: num, id: row.id, typePage: this.typePage}
            })
        },

        //审核记录
        record(row) {
            this.$refs.record.init(row.id,'',10)
        },

        // 选择档案分类
        selRecord(data, checked) {
            //该节点所对应的对象、树目前的选中状态对象
            if (checked) {
                this.archivesBasicDataId = []
                this.archivesBasicDataId = checked.checkedNodes.map(item => {
                    return item.id
                })
            }
            if (this.archivesBasicDataId.length == 0) return
            this.getDataList(1, 1)
        },

        //筛选树的数据
        filterTreeData() {
            this.$refs.tree.filter(this.filterText);
        },

        filterNode(value, data) {
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        },

        //选中文物类别
        selectCulturalRelics(data) {
            if (data == 6) { //其它
                this.inputForm.culturalRelicsType = ''
            } else {
                this.inputForm.other = ''
                this.$axios(this.api.collection.collectionImmovableCulturalRelicType + data, {}, 'get').then(data => {
                    if (data.status) {
                        this.culturalRelicsTypeList = data.data
                    }
                })
            }
        },

        //导出 type 1批量导出 2全部导出
        exportData(type) {
            let ids = []
            if (this.dataListSelect.length == 0) {
                this.$message.warning('请至少选择一条数据')
                return
            }
            ids = this.dataListSelect.map(item => {
                if (item) {
                    return item.id
                }
            })
            this.exportExcel(this.api.collection.collectionImmovableCulturalRelicExport, {ids}, '不可移动文物', 'get')
        },

        //num 1多个 2单个  type 2驳回 3通过
        through(num, row, type) {
            let setData = []
            let revdId
            if (num == 1) {
                if (this.dataListSelect.length == 0) {
                    this.$message.warning('请至少选择一条数据')
                    return
                }
                setData = this.dataListSelect
            } else {
                setData = [row]
            }
            this.$refs.suggestions.init(num, setData, type, 10, this.api.collection.relicRevd)
        },

        //表格勾选数据
        selectionChangeHandle(val) {
            this.dataListSelect = val
        },

        // 展览每页数
        sizeChangeHandle(val) {
            this.pageSize = val;
            this.getDataList(1, 1)
        },
        // 展览当前页
        currentChangeHandle(val) {
            this.pageNo = val;
            this.getDataList('', 2);
        },
    },
}
</script>

<style lang="scss" scoped>
.leftHeighe {
  height: calc(100vh - 124px);
  overflow-y: auto;
}

.treeDateCls {
  overflow-y: auto !important;
  height: calc(100% - 80px);
}

    .magTop {
        margin-top: 10px;
    }

.noData {
    line-height: calc(100vh - 164px);
}

.table {
    .el-table__fixed-right,
    .el-table__fixed {
        height: auto !important;
        bottom: 17px !important;
    }
}

.buIcon {
    font-size: 12px;
    margin-right: 5px;
}
</style>